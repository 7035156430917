.room-details {
  margin-left: -15px;
  margin-right: -15px;
  padding-left: $base-padding/3;
  padding-right: $base-padding/3;
  padding-bottom: $base-padding/3;
  float: left;
  //width: 100%;
  &__content {
    background-color: $brand-color-4;
    border-left: 10px solid $brand-color-3;
    border-right: 10px solid $brand-color-3;
    border-top: 10px solid $brand-color-3;
    border-bottom: 10px solid $brand-color-3;
  }
}

.room-table {
  background-color: $light;
  border-collapse: collapse;
  overflow: auto;
  width: 100%;
  table-layout: fixed;
  tr {
    padding: $base-padding/6;
    border-bottom: 1px solid $brand-color-4;
  }
  tr:last-child {
    border-bottom: 0;
  }
  th {
    border-bottom: 0;
    text-transform: uppercase;
    background-color: $brand-color-4;
  }
  td,
  th {
    padding-left: $base-padding/6;
    padding-top: $base-padding/6;
    padding-bottom: $base-padding/6;
    text-align: left;
  }
  td > .result__book_button {
    width: 70%;
    //margin-left: 20%;
  }
  td > a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    color: $brand-color-2;
  }
  th:nth-child(1) {
    width: 25%;
  }
  td{
  	width: 20%;
  }
  th > button {
    background: transparent;
    border: none;
    padding-right: 1rem;
  }
  th:last-child {
    text-align: right;
  }

  // td:nth-child(2){
  //   padding-left: 5rem;
  // }
  //td:nth-child(3),
  th:nth-child(3) {
    text-align: right;

    // padding-left: 9rem;
  }
  td:nth-child(3) {
 //   padding-left: 4rem;
  }

  // td:last-child,
  // th:last-child{
  //   padding-left: 2rem;
  // }
  &__basis {
    text-transform: uppercase;
  }
  &__room-desc {
    color: $dark;
    .remark-icon {
    	color:$brand-color-5;
    }
  }
  &__currency {
    float: left;
  }
  &__offer-price{
  	text-decoration: line-through;
  }
}
.cancellation-table {
  display: table;
  width: 100%;
  border: 10px solid $brand-color-3;
}
.table-row {
  display: table-row;
  @include clearfix;
}
.table-header {
  display: table-row;
  background-color: $brand-color-4;
  font-size: 0.80rem;
}
.table-head {
  @include make-one-fifth;
  display: table-header-group;
  font-weight: bold;
  float: left;
  text-transform: uppercase;
  padding: 5px;
}
.table-large-head {
  @include make-two-fifth;
  display: table-header-group;

  //font-weight: bold;
  float: left;
  text-transform: uppercase;
  padding: 5px;
}
.table-cell {
  @include make-one-fifth;
  display: table-cell;
  padding: 3px 10px;
}
.table-min-cell {
  @include make-third-of-twentyth;
  display: table-cell;
  padding: 3px 10px;
}
.table-one-fourth-cell {
  @include  make-one-fourth;
  display: table-cell;
  padding: 3px 10px;
}
.table-large-cell {
  @include make-two-fifth;
  display: table-cell;
  padding: 3px 10px;
}
.table-body {
  display: table-row-group;
}
.room-detail-row {
  float: left;
  width: 100%;
}
.cancel-text {
  cursor: pointer;
  color: $brand-color-2;
  &:hover,
  &:active,
  &:focus {
    color: $brand-color-2;
  }
}
.cancellation-header {
  color: $brand-color-2;
  font-size: 0.85rem;
  height: 30px;
  padding: 5px 5px 5px 7px;
  &__seperator {
    border-bottom: solid 1px $brand-color-5;
    padding-left: 5px;
    padding-right: 5px;
    margin-left: 10px;
    margin-right: 10px;
  }
}
.cancellation-detail {
  background-color: $brand-color-4;
  border: 5px solid $brand-color-3;
  margin-left: 16px;
  width: 100%;
}
.cancellation-data {
  padding: 12px;
}
.room-detail-price {
  padding-left: 7px;
  margin-right: 1rem;
  font-family: $font-family-number;

  //text-align: right;
}

.cancellation-container {
  padding: $base-padding/3;
  float: left;
  width: 100%;
  &__content {
  	background-color: $light;
  	border: 5px solid $brand-color-3;
  }
}
.hotel-room {
  @include clearfix;

  &-detail {
    box-shadow: $row-shadow-color;
	background-image: linear-gradient(75deg, $brand-color-2 20%, $brand-color-4 90%);
    width: 100%;
    min-height: 2rem;
    @include clearfix;
	&__header {
		float: left;
        width: 25%;
		padding: 0.375rem;
    	font-size: 0.95rem;
		&_text {
    		color: $light;
    		text-transform: uppercase;
		}
		&_icon {
    		color: $brand-color-2;
            background-color: $light;
            padding: .25rem;
		}
	}
	&__action-btns {
		@include clearfix;
        border-radius: 4px;
        margin-bottom: .5rem;
        padding: .35rem;
        width: 75%;
        float: left;
      	&_book-button, &_cancellation-policy-button {
        	float: right;
        	padding-left: 10px;
        }
        &_cancellation-policy-button {
        .room-listing {
          &-cancellation-policy-button {
            float: right;
            width: 100%;
            
            &__button {
              @include make-button($light, $brand-color-1, $light, darken($brand-color-1, 5));
              @include button-size(0.1rem, $base-padding/2, 0.85rem, inherit, .25rem);
              cursor: pointer;
              float: right;
              font-weight: 700;
              text-transform: uppercase;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      
      &_book-button {
        .room-listing {
          &-book-button {
            float: right;
            width: 100%;

            &__button {
              @include make-button($light, $brand-color-1, $light, darken($brand-color-1, 5));
              @include button-size(0.1rem, $base-padding/2, 0.85rem, inherit, .25rem);
              font-weight: 700;
              width: 100%;
              text-transform: uppercase;
            }
          }
        }
      }
	}
    &__rooms {
      @include clearfix;
      display: table;
      width: 100%;
      padding: 0.95rem .625rem 0.25rem;
      margin-bottom: 0.625rem;

      .fa {
        font-size: $font-size-number;
        float: left;
        color: $brand-color-2;
      }
    }

    &__room-name {
      display: table-row;
      float: left;
      font-size: $font-size-h4;
      margin-bottom: 0.625rem;
      margin-right: 0.625rem;

      @media(max-width: #{$sm-screen-size}) {
        width: 45%;
      }
    }

    &__rooms-desc, &__room-no-desc {
      padding-left: 35px;
    }
    &__rooms-desc {
      color: $brand-color-2;
    }

    &__container {
      width: 100%;
      float: left;
      @media(max-width: #{$sm-screen-size}) {
        width: 90%;
      }
    }

    &__container-view {
      width: 80%;
      float: left;

      @media(max-width: #{$sm-screen-size}) {
        width: 100%;
      }
    }

    &__room-filter {
      @include clearfix;
      width: 20%;
      display: none;
      float: left;
      padding: 0.45rem;

      @media(max-width: #{$sm-screen-size}) {
        display: none;
      }
    }

    &__room-filter-view {
      display: block;

      @media(max-width: #{$sm-screen-size}) {
        display: none;
      }
    }

    &__toggle-filter-view {
      display: block;
      position: absolute;
      box-shadow: $dark-shadow;
      height: auto;
      background: $light;
      z-index: 10;
      padding: 0.5rem;
    }

    &__toggle-filter-btn {
      display: table;
      padding: 0.4rem;
      background: $brand-color-2;
      color: $light;
      cursor: pointer;
      @include clearfix;
      
      &_filter-icon {
        width: 10%;
        padding: 0.2rem;
        float: left;
      }

      &_show-filter,
      &_hide-filter {
        padding:0 5px;
        width: 80%;
        float: left;
	      text-align: center;
        display: inline-block;
        margin: auto;
      }
      &_count {
        float: right;
        width: 10%;
        background: $light;
        color: $brand-color-2;
        border-radius: 0.3125rem;
        text-align: center;
      }
    }

    &__room-list {
      width: 100%;
      float: left;
      position: relative;
    }

    &__total-price-container {
      @include clearfix;
      background-color: $brand-color-4;
      border-radius: 4px;
      margin-bottom: .5rem;
      padding: .5rem;
      width: 100%;

      &_total-price {
        float: left;
        width: 25%;
      }
      &_action-btns {
        float: left;
        width: 75%;
      }
      &_book-button, &_cancellation-policy-button {
        float: right;
        padding-left: 10px;
      }
     

      &_total-price {
        .total-price {

          &__label,
          &__value {
            float: left;
          }

          &__label {
            color: $brand-color-2;
            font-size: 1rem;
            margin-top: 0.25rem;
          }

          &__value {
            margin-left: .5rem;


            &_amount {
              color: $brand-color-1;
              font-family: $font-family-number;
              font-size: 1.25rem;
              margin-left: .25rem;
            }

            &_amount-with-adhoc {
              @extend %adhoc-button-style;
            }
          }
        }
      }
      &_cancellation-policy-button {
        .room-listing {
          &-cancellation-policy-button {
            float: right;
            width: 100%;
            
            &__button {
              @include make-button($brand-color-2, $brand-color-3, $brand-color-2, darken($brand-color-3, 5));
              @include button-size($base-padding/5, $base-padding/2, 0.875rem, inherit, .25rem);
              cursor: pointer;
              float: right;
              background-color: $brand-color-3;
              text-transform: uppercase;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
      
      &_book-button {
        .room-listing {
          &-book-button {
            float: right;
            width: 100%;

            &__button {
              @include make-button($light, $brand-color-1, $light, darken($brand-color-1, 5));
              @include button-size($base-padding/5, $base-padding/2, 0.875rem, inherit, .25rem);
              font-weight: 700;
              width: 100%;
              text-transform: uppercase;
            }
          }
        }
      }
      }
    
    &__rooms-and-filters-list-container{
    	@include clearfix;
    	position: relative;
  	}
  	&__error-msg{
		padding:unset;
	}
  }
}
.hotel-room-error-container {
  	
  	@include clearfix;
  	padding: 1rem;
  	
  	&__content{
 		background-color: lighten($brand-color-1, 40);
    	text-align: center;
    	padding: 0.5rem;
    	color: $brand-color-1;
    	line-height: 1.5rem;
    	text-transform:uppercase;
	}
}
.hotel-quote-notification-modal{
    font-size: $font-size-h5;
    font-weight: 600;
    text-align: center;
    &__content{
    	&_text{
		    color: $brand-color-19;
		    padding-bottom: 0.2rem;
    		font-size: 0.8rem;
    		padding-top: 1.1rem;
	    }
	    &_button{
    	    width: 15%;
			padding: 0.25rem;
			background-color: $brand-color-3;
			color: $brand-color-2;
	    }
	}
}

.modal-open{
	.hotel-quote-notification-modal.modal {
    		overflow: hidden;
	}
}
.hotel-quote-notification-modal {
		.modal-content {
    		max-width: 35%;
    		margin-left: 35%;
   			margin-top: 30%;
    		border: 2px solid $brand-color-2;
		}
}
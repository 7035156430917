.hotel__review {
  @include create-main-wrapper;
  @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include create-row-card-footer;
  @include create-hotel-row;
  &_main {
    padding-top: $base-padding/2;
    .content {
      padding-left: $base-padding/2;
      padding-right: 0;
    }
  }
  &_card {
  	padding-bottom: 2rem;
    box-shadow: $row-shadow-color;
    &_title {
      text-transform: uppercase;
      color: $brand-color-2;
      padding: 0.9375rem 0 0.375rem 0.375rem;
    }
    &_header {
      background-color: $brand-color-4;
      text-transform: uppercase;
    }
    .header_text {
      margin-right: $base-margin;
    }
  @include create-review-nav;
  }
}
.hotel-details {
  @include create-main-wrapper;
  @include create-row;
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  @include create-row-card-footer;
  @include create-hotel-row;
  &_card {
    box-shadow: $row-shadow-color;
  }
  &__content {
    margin-bottom: $base-margin/2;
    @include clearfix;
  }
  &__title {
    text-transform: uppercase;
    padding: 0.9375rem 0 0.375rem 0.375rem;
    padding-left: 0;
    @include clearfix;
     &_main-title{
      color: $brand-color-2;
   	  float: left;
      width:45%;
     }
     &_sub-title{
     	 color:$brand-color-5;
    	 float:left;
    	 width:45%;
	     text-align:right;
     }

  }
  &__header {
    width: 100%;

    //margin-left:20%;
    background-color: $brand-color-4;
    padding: $base-padding/6;
    text-transform: uppercase;
    @include clearfix;
    .header-empty {
      @include make-one-fifth;
    }
    .header-text {
      @include make-four-fifth;
      @include clearfix;
      padding-left: 0;
      .header_text {
        @include make-three-fifth;
        padding-left: 3px;
        text-align: left;
        &:last-child {
          @include make-two-fifth;
          padding-left: 1.7rem;
        }
      }
    }
  }
}
.hotel__cancellation_heading,
.hotel__amendment-policy_heading,
.hotel__highlight_heading {
  font-size: $font-size-h4;
  color: $brand-color-2;
  padding-top: $base-padding/2;
  padding-bottom: $base-padding/3;
}
.hotel__board-basis_sub-heading {
  color: $brand-color-2;
}
.hotel{
	&__room-extra-details{
		 input[type="text"]{
			text-transform: uppercase;
		}
	}
}
.content {
  &__description-top,
  &__description-bottom {
    @include clearfix;
    @include make-four-fifth;

    //padding:$base-padding/3 0 $base-padding/3 0;
  }
  &__description-top {
    border-bottom: 2px solid $brand-color-4;
  }
}
.description-top,
.description-bottom {
  &__hotel {
    &_name,
    &_city {
      @include make-three-fifth;
      max-height: 100px;
      text-align: left;
      font-family: $font-family-number;
      color: $brand-color-2;
      font-size: 1rem;
      font-weight: 700;
      text-transform: capitalize;
    }
    &_city {
      font-size: 0.95rem;
      font-weight: 400;
      color: $brand-color-5;
    }
  }
  &__country {
    @include make-four-fifth;
    text-transform: uppercase;
    font-size: 0.75rem;
    &_name {
      max-height: 100px;
      text-align: left;
      font-family: $font-family-number;
      color: $brand-color-5;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
  &__room-type {
    font-weight: 400;
    font-size: 0.75rem;
    @include make-one-fifth;
    max-height: 100px;
    text-transform: capitalize;
  }
  &__rating {
    font-size: 0.85rem;
    @include make-two-fifth;
    max-height: 100px;
    .glyphicon {
      color: $star-rating;
    }
  }
}

.review-fare-details {
  @include create-row-card;
  @include create-row-card-header;
  @include create-card-content;
  &_card {
    box-shadow: $row-shadow-color;
    &_header {
      text-transform: uppercase;
      color: $brand-color-2;
      padding-left: 0px;
    
    }
    .header_text {
      margin-right: $base-margin;
    }

  }
  &__table {
    table {
      width: 100%;
      font-family: $font-family-number;
      thead {
        text-transform: uppercase;
        background-color: $brand-color-4;
        th:first-child {
          padding-left: 0.625rem;
          padding-right: 9.375rem;
          padding-top: 0.3125rem;
          text-align: left;

        }
        th {
          padding: 0.3125rem;
          text-align: right;
        }
      }
      tbody {
        tr {
          border-bottom: 2px solid $brand-color-4;
          td {
            padding: 0.3125rem;
            text-align: right;
            color: $brand-color-2;
            &:first-child {
              padding-left: 0.625rem;
              padding-right: 0.3125rem;
              padding-top: 0.3125rem;
              padding-bottom: 0.3125rem;
              text-align: left;
              color: $brand-color-5;
            }
          }
          &:last-child {
            background-color: $brand-color-4;
            td {
              padding: 0.3125rem;
              text-align: right;
              color: $dark;
              &:first-child {
                padding-left: 0.625rem;
                padding-right: 0.3125rem;
                padding-top: 0.3125rem;
                padding-bottom: 0.3125rem;
                text-align: left;
                text-transform: uppercase;
              }
              &:last-child{
                text-align: right;
              }
            }
          }
        }
      }
    }
  }
 }

// add guest-occupancy-card scss
@include create-guest-occupancy-card;
.text-right {
  text-align: right;
}

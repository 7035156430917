.hotel__pax-info {
  @include create-pax-info;
  padding-left: 0.9375rem;
  &_footer-row{
  	@include clearfix();
	padding-top: .9375rem;
  	padding-bottom: .9375rem;
  }
  &_save-traveler{
	  	margin-bottom:0.9375rem;
   }
   &_cancellation-policy-header {
   		float: left;
    	font-size: 0.875rem;
    	margin: 2px;
   }
}
.view__cart {
  float: right;
  padding: $base-padding/6;
}
.hotel__pax-info_main .content {
  padding-left: 0.9375rem;
}
.hotel__pax-info_footer{
  .hotel {
    @include create-ssr-styles;
  }
}

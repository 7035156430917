  
.hotel__search_result {
 .map-view__wrapper {
    margin-top: -10px;
    margin-bottom: 15px;
    position: relative;

    &_map {
    	border: 3px solid $brand-color-2;
    }
}

	.room-selected__wrapper{
    	padding: 0.25rem;
    	color: $brand-color-20;
    	background-color: $brand-color-21;
    	margin-bottom: 1.2rem;
		&_message{
			font-size: 0.8rem;
    		padding-left: 0.25rem;
    		position: relative;
    		.message{
    			&__close{
    				display: inline-block;
    				background: none;
    				color: $brand-color-20;
    				position: absolute;
    				right: 0rem;
    				padding-right: 0.5rem;
    				font-size: 1rem;
    				font-family: monospace;
    				cursor: pointer;
    			}
    		}
		}
	}
	.hotel-list{
		&__wrapper{
		    margin-bottom: 1rem;
		    position: relative;
		    margin-top: -($base-margin/2);
			
			&_container {
    			border: 3px solid $brand-color-6;
    			background-color: $light;
			}
		}
	}
	.hotel-row{
	    background: $light;
	    box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
	    margin: 0.625rem;
	    padding-bottom: 0.125rem;
	   	&__content {
	   		margin: 0.625rem;
		    height: 100px;
		    margin-bottom: 0;
	   	}
	   	&__btn-container {
	   		position: absolute;
    		right: 30px;
	   	}
	   	&__footer {
	   		height: 25px;
    		background: $brand-color-4;
    		
    		&_view-rooms {
    			float: right;
			    min-height: 1px;
			    width: 20%;
			    padding-left: 0.625rem;
			    padding-right: 0.625rem;
			    border-color: $brand-color-6;
    		}
	   	}
	   	
	   	&__rooms-content {
	   		padding: 0.525rem;
	   		padding-left: 1.25rem;
		    border: 0.0935rem solid $brand-color-6;
		    margin: 0.425rem;
		    box-shadow: 0 2px 0 0 rgba(0,0,0,.2);
		    border-radius: 0.25rem;
	   	}
	   	&__rooms {
	   		&_room-desc {
	   			color: $gray;
			    font-size: 0.875rem;
			    text-transform: uppercase;
	   		}
	   	}
		&__result{
			&_hotel {
				float: left;
			    min-height: 1px;
			    width: 20%;
			  	padding: 0.625rem;
			    border-right: 1px solid $brand-color-6;
			    
			    .hotel{
				    &__image-container {
				    	text-align: center;
	    				max-height: 100px;
	    				
	    				&_image {
	    					width: 100%;
						    max-height: 80px;
						    cursor: pointer;
	    				}
				    }
				}
			}
		}
		 
		&__details {
			width: 60%;
			float: left;
			border-right: 1px solid $brand-color-6;
			min-height: 100px;
			
			&_hotel {
				padding-bottom: 0.625rem;
			    padding-top: 0.625rem;
			}
		   	
		   	&_rating {
		   		width: 45%;
    			float: left;
    			color: $star-rating;
		   	}
		   	&_hotel-name {
		   		max-height: 100px;
			    text-align: left;
			    font-family: $font-family-sans-serif;
			    color: $brand-color-2;
			    font-size: 1rem;
			    font-weight: 400;
			    text-transform: uppercase;
			    margin-left: 1rem;
			    float: left;
			    width: 50%;
		   	}
		}
		 &__price {
    		padding: 0.675rem;
    		float: left;
   			width: 20%;
    		&_amount {
			    font-size: 1.125rem;
			    font-family: $font-family-number;
			    color: $brand-color-1;
    		}
		} 
	}
  .map__wrapper {
    height: 500px;
    width: 100%;
  }
  .search__result {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-footer;
    &_row {
		position: relative;
     @include create-recommended-product-icon();
    }
    &_card {
      box-shadow: $row-shadow-color;
    }
    &_highlight {
      border: 2px solid $brand-color-2;
    }
    &_hotel,
    &_price {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;
      padding-top: $base-padding/3;
    }
    
    &_tax-header{
	    @include clearfix;
		margin-top: -22px;
		margin-left: 25%;
		margin-right: 15px;
		padding: 0.25rem;
	    color: $dark-green;
	    background-color: $saffron;
	    box-shadow: $row-shadow-color;
	    margin-bottom: 1rem;
	    	.tax-message{
	    		font-size: 0.75rem;
	    		padding-left: 0.25rem;
	    	}
    }
    
	&_header{
		text-transform:uppercase;
		color:$brand-color-2;
		border-bottom: 1px solid darken($brand-color-4,20);
		padding-top: 0.5rem;
		padding-bottom: 1rem;
        padding-left: $base-padding/3;
        padding-right: $base-padding/3;		
	    margin-bottom: $base-margin/5;
	    margin-left: -15px;
    	margin-right: -$base-margin/2;
	    >span {
	    	float:right;
	    	color:$brand-color-2;
	    }
	   
	   	.add-nights-number-stepper,
	    .add-sight-seeing{
	   		    width: 180px;
	   		    padding-left: 15px;
	   		     input[type="text"],
  select {
    width: 100%;
    padding: .4rem .5rem;
    background: none;
    font-size: 0.875rem;
    vertical-align: middle;
    line-height: normal;
    border: 1px solid;
    border-color: $input-border-color;
    max-height: 34px;
  }
  input[type="text"] {
    background-color: $input-bgcolor;
    border: 1px solid;
    border-color: $input-border-color;
    max-height: 34px;
    position: relative;
  }
  .number-stepper {
    max-width: 250px;
    input[type="text"] {
      text-align: center;
    }
   .input-group-btn .btn {
    padding: .375rem .5rem;
   }
   
    button[disabled]{
    	cursor:not-allowed;
    	color:$gray-light;
    	
    	&:hover{
    		background-color: $gray-light;
    		color:$light;
    	}
    }
  }
  label {
    margin-bottom: 0.25rem;
    color: $sf-label-color;
    font-size: 0.875rem;
  } 

	   	}
	}
    &_detail {
      @include make-three-fifth;
  	  padding-right: 0px;
      padding-left: 0px;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: $brand-color-3;
      min-height: calc(#{$base-padding}/3 + 80px);
    }
    &_description,
    &_extra-desc {
      padding-top: $base-padding/3;
      @include clearfix;
      width: 100%;
    }
    &_description {
      padding-bottom: $base-padding/3;
    }
  }

.map {
  height: 100%;
}
// hOTEL
.description {
  &__hotel {
    @include make-three-fifth;
    padding-left: 0;
    &_name,
    &_city {
      max-height: 100px;
      text-align: left;
      font-family:$font-family-sans-serif;
      color: $brand-color-2;
      font-size: 1rem;
      font-weight: 400;
      text-transform: uppercase;
      margin-left: 1rem;
    }
    &_city {
      color: $brand-color-5;
      font-size: 12px;
      font-weight: 600;
    }
    &_map-view{
    	cursor: pointer;
     text-transform: uppercase;
     font-size: $font-size-h6;
     font-weight: 600;
     color: $brand-color-1;
     padding-left: 1rem;
     }
  }
  &__rating {
    @include make-two-fifth;
    padding-right: 0px;
    max-height: 100px;
    .glyphicon {
      color: $star-rating;
    }
  }
}
.extra-desc__hotel {
  @include make-three-fifth;
  padding-left: 0;
  &_location {
    color: $brand-color-5;
    max-height: 100px;
    text-align: left;
    font-family: $font-family-number;
    font-size: 12px;
    font-weight: 600;
  }
}

.hotel__roomdesc {
    @include make-two-fifth;
    padding-right: 0px;
    &_text {
      color: $brand-color-5;
      text-transform: capitalize;
    }
  }

.result {
  &__extra_details {
    font-size: 0.8125rem;
    
    &_hotel-highlights, &_hotel-cancellation-policy,&_day-wise-rates {
      @include make-three-tenth;
      cursor: pointer;
      padding: .3125rem 3.5rem;
      float: left;
      text-transform: uppercase;
      color: $brand-color-2;
      background: none;
      font-size: .625rem;
      font-weight: 600;
      text-align: left;
      width: initial !important;
      background-color: $brand-color-3;
      &:hover,
      &:active,
      &:focus {
         cursor: pointer;
    	 text-transform: uppercase;
    	 font-size: .625rem;
    	 font-weight: 600;
         color: $row-flight-details-button-state;
         text-decoration: none;
       }
       &:hover {
      	 background-color:$brand-color-3;
         text-decoration: underline;
       }
       &:disabled{
    	cursor: not-allowed;
      }
    }
    
    &_hotel-cancellation-policy {
    	float: right;
    }
    
    &_supplier{
    	float:left;
    	width:30%;
    	color:$brand-color-2;
    }
    &_cancellation {
      @include make-three-tenth;
      cursor: pointer;
      background: none;
      text-align: center;
      margin-right: 5rem;
      padding: .3125rem 0;
      background-color: $brand-color-3;
      font-size:0.625rem;
      padding-top: 5px;
    }
    &_highlight-duplicate {
      float: left;
      color: $brand-color-1;
      width:5%;
      font-size: 1rem;
      	.highlight-duplicate{
        	&__button{
        		background-color: $brand-color-4;
        	}
        }
     }
    &_change-room {
      //@include make-two-fifth;
    	cursor: pointer;
	    text-transform: uppercase;
	    color: black;
	    background-color: $brand-color-3;
	    padding-left: 20px;
	    padding-right: 20px;
	    padding-top: 3px;
	    padding-bottom: 2px;
	    float: right;
	    margin-bottom: -11px;
	    &:disabled{
    	  cursor: not-allowed;
    	}
    }
    &_offers {
      @include make-three-tenth;
      cursor: pointer;
    }
 	.details-content{
		float:right;
	}  	
	.supplier-name {
		float: left;
		font-weight: 600;
		padding: 0.19rem 0rem;
	}
  }

.offer{
  &__text{
    text-transform: uppercase;
      color: $brand-color-2;
  }
}

.cancellation{
  &__text{
    text-transform: uppercase;
      color: $brand-color-2;
  }
}

  &__price {
    padding-top: $base-padding/4;
    max-height: 100px;
    position: absolute;
    height: 90%;
    width: inherit;
  }
  &__status {
  	position: absolute;
  	width: 18%;
  	bottom: 0px;
  	text-align: center;
  }
  &__hotel {
    text-align: center;
    max-height: 100px;
    width: 100%;
    img {
      max-height: 80px;
      cursor: pointer;
      max-width: 100%;
    }
  }

  &__price {
    &_currency {
    }
    &_amount {
      font-size: $font-size-h3;
      font-family: $font-family-number;
      color: $brand-color-1;
      
      // font-weight: 400;
    }
    &_amount-with-adhoc{
     	color: $brand-color-14;
    }
    &_adhoc-amount{
    	display: inline-block;
    	width: 100%;
     	text-align: center;
    }
    &_starting-from {
    	text-transform: capitalize;
    }
  }
  
  &__offer-price{
  		position: relative;
    	text-decoration: line-through;
	  &_amount{
	  	 font-size: $font-size-h3;
      	 font-family: $font-family-number;
	  }
  }

  &__hotel_details_button,
  &__extra_details_text,
  &__book_button,
  &__select_button,
  &__select_button-active{
    cursor: pointer;
    text-transform: uppercase;
    font-size: $font-size-h6;
    font-weight: 600;
    &:disabled{
    	cursor: not-allowed;
    }
  }

  &__hotel_details {
    @include make-one-fifth;
    padding-left: $base-padding/3;
    padding-right: $base-padding/3;

    border-color: $brand-color-3;
    &_button {
      width: 100%;
      background-color: $brand-color-4;
      padding-top: $base-padding/6;
      padding-bottom: $base-padding/6;
      background-color: $brand-color-3;
      display: block;
      text-align: center;
      color: $brand-color-2;
      &:hover,
      &:active,
      &:focus {
         cursor: pointer;
      	 text-transform: uppercase;
      	 font-size: .625rem;
      	 font-weight: 600;
         color: $row-flight-details-button-state;
         text-decoration: none;
      }
      &:hover {
      	 background-color:$brand-color-3;
         text-decoration: underline;
      }
      &:disabled{
    	cursor: not-allowed;
      }
    }
  }

  &__extra_details {
    @include make-three-fifth;
    background-color: $row-footer-bgcolor;
    border-left: 1px solid;
    border-right: 1px solid;
    border-color: $brand-color-3;
    &_text {
      float: left;
    }
  }
  &__book,
  &__select {
    @include make-one-fifth;
    padding-left: $base-padding/3;
    padding-right: $base-padding/3;
    border-color: $row-book-border-color;
    &_button, &_button-active {
      @include button-size($base-padding/6, 0, $font-size-h6, inherit, 0);
      @include make-button($light, $brand-color-1, $light, darken($brand-color-1, 20));
      width: 100%;
      font-weight: 700;
    }


  }
  &__select {
    &_button {
      @include make-button($light, $brand-color-2, $light, darken($brand-color-2, 20));
    }
    &_button-active {
      @include make-button($brand-color-2, $brand-color-3, $brand-color-2, $brand-color-3);
    }
  }
}
.triangle {
  position: relative;
  margin-left: 5px;
  top: 7px;
  border-bottom-width: 0px !important;
  @include make-triangle(bottom, 5px, $light);
}
.triangle-active {
  position: relative;
  margin-left: 5px;
  top: 7px;
  border-bottom-width: 0px !important;
  @include make-triangle(bottom, 5px, $brand-color-2);
}

}

.modal{
	&__content{
		&_cancellation-loader{
			@include make-loader;
			}
		&_day-wise-price {
			width:100%;
			.day-wise-price {
				&__data{
    				width: 100%;
    				line-height: 2;
					&_header {
						font-weight: 600;
						width : 15%;
						min-width: 120px;
    					color: $brand-color-2;
					}
					&_content, day-wise-status {
						padding: 0.225rem;
						width:17%;
						min-width: 120px;
					}			
					&_day-wise-status {
						color: $brand-color-1
					}	
					&_price{
						color: $brand-color-1;
						font-family: $font-family-number;
						font-size: 0.825rem;
					}
					&_currency{
						font-size: 0.625rem;
					}
					&_border-and-alignment{
						border: 1px solid $hr-border-color;
						text-align: center;
					}
				}
				&__status {
					margin-top: 7px;
					min-height: 2.1875rem;
	    			padding: 0.3125rem 0.3125rem 0.3125rem 0.3125rem;
	    			color: $brand-color-1;
				}
			}
		}
	}
	&__header {
		&_info{
			font-size : 1rem;
		}
	}
}
.hotel-result-price-status{
	position: absolute;
  	width: 100%;
  	bottom: 0px;
  	text-align: center;
  	&__available,
  	&__unavailable,
	&__price-changed{
		margin: 0 auto;
	    max-width: 150px;
	    color: $orange;
	}
	&__available{
	    color: $green;
	}
	&__unavailable{
	    color: $brand-color-9;
	}
}

.special-description {
	max-width:300px;
	padding-bottom: 0.5rem;
	&__block {
		font-size: 0.675rem;
		font-weight: 600;
		display: inline-block;
		width: 100%;
		padding-top: 0.25rem;
		word-wrap: break-word;
	}
}

.offer-box {
&__data {
	 	.glyphicon.glyphicon-ok-sign{
	 	padding:0.25rem;
	 	color: $brand-color-2;
	}
  }
}

/* HTML marker styles */
.price-tag {
  background-color: $brand-color-2;
  border-radius: 8px;
  color: $light;
  font-size: 0.75rem;
  padding: 0.5rem 0.3125rem;
  position: relative;
}

.price-tag::after {
  content: "";
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%, 0);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid $brand-color-2;
}
.hotel__search_result {
 @include search-info;
 .search-details {
  @include create-main-wrapper;
  background-color: $md-bgcolor;
  &_wrapper {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  &__row {
    @include clearfix;
    // margin-left: 15px;
    > li {
      @include make-one-fourth;
      padding-left: 0;
      padding-right: 0;
      border-right: 2px solid $md-border-color;
      &:first-child {
        border-left: 2px solid $md-border-color;
      }
      &:nth-child(3) {
        border-right: none;
      }
      &:last-child {
        padding-right: $base-padding/2;
        border-right: none;
      }
    }
    &_traveller {
      margin-right: -15px;
    }
  }


  .checkin,
  .checkout,
  .destination {
    &__data
     {
      @include clearfix;

      // border-left: 2px solid white;
      padding-left: $base-padding/2;
      padding-right: $base-padding/2;
      &_icon {
        float: left;
        font-size: 2.25rem;
        width: 20%;
        > img {
			height: 3rem;
		}
      }
      &_detail {
        float: left;
        padding: 9px;
        width: 80%;
        .location,
        .check-in,
        .check-out {
          color: $md-heading-color;
        }
        .departure,
        .checkin-date,
        .checkout-date {
          @include clearfix;
        }
      }
    }
  }

  .destination__data,
  .checkin__data,
  .checkout__data
   {
    &_icon {
      span.glyphicon.glyphicon-arrow-right,
      span.glyphicon.glyphicon-arrow-left,
      span.glyphicon.glyphicon-map-marker{
      //  transform: rotate(45deg);
        top: 5px;
            }
    }
  }

  .traveller {
   &__row {
     @include clearfix;
     > li {
       text-align: center;
       width: 25%;
       border-left: 2px solid $md-border-color;
       padding-top: 9px;
       padding-bottom: 9px;
       float: left;
       &:last-child {
         border-right: 2px solid $md-border-color;
       }
     }
   }
 }
}

}

.checkin-icon,.checkout-icon{
	font-size: .80rem;
    margin-bottom: 0.3125rem;
	&__checkout-arrow,
	&__checkin-arrow{
		margin-left: -0.5rem;
        margin-top: 0.25rem;
	}
	&__checkout-arrow{
		margin-left: 0.75rem;
        margin-top: 0.25rem;
	}
}

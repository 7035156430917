@include make-no-of-nights-card;
.hotel__search_result {
  .search__review {
    @include create-main-wrapper;
    @include create-row;
    @include create-row-card;
    @include create-row-card-footer;
    &_card {
      box-shadow: $row-shadow-color;
    }
    &_hotel {
      @include make-one-fifth;
      padding-right: $base-padding/3;
      padding-top: $base-padding/3;
      padding-bottom: $base-padding/3;
    }
    &_detail {
      @include make-four-fifth;
      min-height: calc(#{$base-padding}/3 + 80px);
    }
    &_description,
    &_extra-desc {
      padding-top: $base-padding/3;
      @include clearfix;
      width: 100%;
    }
    &_description {
      border-bottom: 2px solid $brand-color-4;
      padding-bottom: $base-padding/3;
    }
  }

  // hOTEL
  .review-description {
    &__hotel {
      @include make-three-fifth;
      padding-left: 0;
      &_name,
      &_city {
        max-height: 100px;
        text-align: left;
        font-family: $font-family-number;
        color: $brand-color-2;
        font-size: 1rem;
        font-weight: 400;
        text-transform: capitalize;
      }
      &_city {
        color: $brand-color-5;
        font-size: 12px;
        font-weight: 600;
      }
    }
    &__rating {
      @include make-two-fifth;
      padding-right: 0;
      max-height: 100px;
      .glyphicon {
        color: $star-rating;
      }
    }
  }
  .extra-desc__hotel {
    @include make-three-fifth;
    padding-left: 0;
    &_location {
      color: $brand-color-5;
      max-height: 100px;
      text-align: left;
      font-family: $font-family-number;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .hotel__roomdesc {
    @include make-two-fifth;
    padding-right: 0;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    &_text {
      color: $brand-color-5;
      text-transform: capitalize;
      padding-bottom: 0.3125rem;
    }
  }
  .review {
    &__extra_details {
      font-size: 0.625rem;
      &_hotel-highlights {
        @include make-three-fifth;
        cursor: pointer;
        padding-right: 0;
        padding-left: 0;
        float: left;
        text-transform: uppercase;
        color: $brand-color-2;
      }
      &_cancellation {
        @include make-two-fifth;
        cursor: pointer;
        padding-right: 0;
        float: right;
      }
    }
    &__hotel {
      text-align: center;
      max-height: 100px;
      img {
        width: 100%;
        max-height: 80px;
      }
    }
    &__price {
      &_currency {

      }
      &_amount {
        font-size: $font-size-h3;
        font-family: $font-family-number;
        color: $brand-color-1;

        // font-weight: 400;
      }
    }
    &__hotel_details {
      @include make-one-fifth;
      padding-left: $base-padding/3;
      padding-right: $base-padding/3;
      border-color: $brand-color-3;
      &_button {
        width: 100%;
        background-color: $brand-color-4;
        padding-top: $base-padding/6;
        padding-bottom: $base-padding/6;
        display: block;
        text-align: center;
        color: $dark;
        &:hover,
        &:active,
        &:focus {
          color: $row-flight-details-button-state;
          text-decoration: none;
        }
        &:hover {
          text-decoration: underline;
        }
      }
    }
    &__extra_details {
      @include make-three-fifth;
      padding-top: $base-padding/6;
      padding-bottom: $base-padding/6;
      background-color: $row-footer-bgcolor;
      border-left: 1px solid;
      border-right: 1px solid;
      border-color: $brand-color-3;
      &_text {
        float: left;
      }
    }
  }
}

.pricing-breakup__pricing-breakup-header{
	color: $brand-color-2;
  font-size: $font-size-h4;
  &_pricing-breakup-heading {
    padding-left: 7px;
    float:left
  }
  &_close{
    float:right;
    cursor:pointer;
  }
  &_info {
    margin-top: 7px;
    font-size: $font-size-h3;
    background-color: lighten($brand-color-3,8);
    height: 35px;
    padding: 5px 5px 5px 7px;
  }
  .alert__message--error{
    margin-top:10px;
  }
}
.pricing-breakup-heading{
  &__main{
	 @include clearfix;
  }
}
.pricing-breakup-heading{
  &__info{
	&_hotel-name{
		  color: $brand-color-2;
          float: left;
	}
  }
}
.pricing-breakup-heading{
   &__info{
       &_total-price {
	  color: $brand-color-2;
	  float: right;
	  font-size: 0.875rem;
	  margin: 2px;
	  margin-top : 0rem;
	  font-family: $font-family-number;
	  }
   }
}
.cancellation-policy {
	&_room {
		&-header {
			color: $brand-color-2;
			padding: $base-padding/3;
			border-bottom: 1px solid $gray-lighting;
		}

		&-content {
			padding: $base-padding/3;
		}
	}
}